import React, { useState, useContext } from 'react';
import { NavbarContainer, ProfileCard, TextHome } from './navbar.style';
import { Link } from 'react-router-dom';
import { ReactComponent as HarpiWhite } from '../../assets/logo-harpi-white.svg';
import { ReactComponent as HomeIcon } from '../../assets/home-icon.svg';
import { ReactComponent as HomeIconSelec } from '../../assets/home-icon-selec.svg';
import { ReactComponent as PanelIcon } from '../../assets/panel-icon.svg';
import { ReactComponent as PanelIconSelec } from '../../assets/panel-icon-selec.svg';
import { ReactComponent as SuppliersIcon } from '../../assets/suppliers-icon.svg';
import { ReactComponent as SuppliersIconSelec } from '../../assets/suppliers-icon-selec.svg';
import { ReactComponent as ConfigurationsIcon } from '../../assets/configurations-icon.svg';
import { ReactComponent as ConfigurationsIconSelect } from '../../assets/configurations-icon-selec.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/arrow-down-icon.svg';
import { useTranslation } from 'react-i18next';
import { Context } from '../../Context/Auth';
import { userHasAnyPermissionOf } from '../../services/permission-service';

interface NavbarProps {
  selectedMenu: string;
  pageTitle: string;
}

const Navbar: React.FC<NavbarProps> = ({
  selectedMenu = '',
  pageTitle = '',
}) => {
  let menu: { [key: string]: boolean } = {
    home: false,
    panel: false,
    suppliers: false,
    configurations: false,
  };
  menu[selectedMenu] = true;
  const [newMenu, setSelected] = useState(menu);
  const [profileCardIsOpen, setProfileCard] = useState(false);
  const { t } = useTranslation();
  const { user, handleLogout } = useContext(Context);

  const toogleSelected = (type: string) => () => {
    menu = {
      home: false,
      panel: false,
      suppliers: false,
      configurations: false,
    };
    menu[type] = !menu[type];
    setSelected(menu);
  };
  const handleProfileCard = () => {
    setProfileCard(!profileCardIsOpen);
  };

  const buildNameInitials = () => {
    const name = user.name;
    return name ? name[0] : '';
  };
  return (
    <NavbarContainer className={`w-full px-12 xl:px-20`}>
      <div className="flex justify-between">
        <div>
          <HarpiWhite className="pt-1 ml-5" width="10rem" height="4.2rem" />
        </div>
        <div className="flex pt-2 w-full justify-center">
          {userHasAnyPermissionOf(['view_rfq']) && (
            // <div className="px-4 text-center cursor-pointer">
            //   {newMenu.home ? (
            //     <HomeIconSelec className="w-5 h-4 m-auto" />
            //   ) : (
            //     <HomeIcon className="w-5 h-4 m-auto" />
            //   )}
            //   <span
            //     className={`text-sm ${
            //       newMenu.home
            //         ? 'text-white font-semibold'
            //         : 'text-yaleblue-400'
            //     }`}
            //   >
            //     {t('home')}
            //   </span>
            //   {newMenu.home && (
            //     <div className="bg-white w-2 h-2 rounded-full m-auto" />
            //   )}
            // </div>
            <Link
              className="px-4 text-center cursor-pointer"
              onClick={toogleSelected('home')}
              to={'/home'}
            >
              {newMenu.home ? (
                <HomeIconSelec className="w-5 h-4 m-auto" />
              ) : (
                <HomeIcon className="w-5 h-4 m-auto" />
              )}
              <span
                className={`text-sm ${
                  newMenu.home
                    ? 'text-white font-semibold'
                    : 'text-yaleblue-400'
                }`}
              >
                {t('home')}
              </span>
              {newMenu.home && (
                <div className="bg-white w-2 h-2 rounded-full m-auto" />
              )}
            </Link>
          )}
          {userHasAnyPermissionOf([
            'view_purchaserequisition',
            'view_item',
            'view_rfq',
          ]) && (
            <Link
              className="px-4 text-center cursor-pointer"
              onClick={toogleSelected('panel')}
              to={'/purchase-panel'}
            >
              {newMenu.panel ? (
                <PanelIconSelec className="w-5 h-4 m-auto" />
              ) : (
                <PanelIcon className="w-5 h-4 m-auto" />
              )}
              <span
                className={`text-sm ${
                  newMenu.panel
                    ? 'text-white font-semibold'
                    : 'text-yaleblue-400'
                }`}
              >
                {t('purchase_panel')}
              </span>
              {newMenu.panel && (
                <div className="bg-white w-2 h-2 rounded-full m-auto" />
              )}
            </Link>
          )}
          {userHasAnyPermissionOf(['view_supplier']) && (
            <Link
              className="px-4 text-center cursor-pointer"
              onClick={toogleSelected('suppliers')}
              to={{
                pathname: '/suppliers',
                state: { active_tab: 'suppliers' },
              }}
            >
              {newMenu.suppliers ? (
                <SuppliersIconSelec className="w-5 h-4 m-auto" />
              ) : (
                <SuppliersIcon className="w-5 h-4 m-auto" />
              )}
              <span
                className={`text-sm ${
                  newMenu.suppliers
                    ? 'text-white font-semibold'
                    : 'text-yaleblue-400'
                }`}
              >
                {t('suppliers')}
              </span>
              {newMenu.suppliers && (
                <div className="bg-white w-2 h-2 rounded-full m-auto" />
              )}
            </Link>
          )}
          {userHasAnyPermissionOf(['view_historicalquotation']) && (
            <Link
              className="px-4 text-center cursor-pointer"
              onClick={toogleSelected('configurations')}
              to={'/settings-panel'}
            >
              {newMenu.configurations ? (
                <ConfigurationsIconSelect className="w-5 h-4 m-auto" />
              ) : (
                <ConfigurationsIcon className="w-5 h-4 m-auto" />
              )}
              <span
                className={`text-sm ${
                  newMenu.configurations
                    ? 'text-white font-semibold'
                    : 'text-yaleblue-400'
                }`}
              >
                {t('configurations')}
              </span>
              {newMenu.configurations && (
                <div className="bg-white w-2 h-2 rounded-full m-auto" />
              )}
            </Link>
          )}
        </div>
        <div className="pt-4">
          <div className="flex items-center justify-end">
            <div
              className="flex cursor-pointer"
              onClick={() => handleProfileCard()}
            >
              <div className="w-10 h-10 rounded-full bg-white ml-7 text-center pt-2 uppercase">
                {buildNameInitials()}
              </div>
              <ArrowDownIcon className="w-4 h-2 ml-2.5 cursor-pointer mt-4" />
            </div>
            {profileCardIsOpen && (
              <ProfileCard
                className="absolute bg-white border border-t-0 hide items-center mt-24 px-4 py-3 rounded-b-lg z-10"
                id="profileMenu"
              >
                <div
                  className="flex items-center"
                  onClick={() => handleLogout()}
                >
                  <span className="block text-bluejeans-800 hover:bg-grey-lighter text-center cursor-pointer text-sm font-semibold">
                    {t('logout')}
                  </span>
                </div>
              </ProfileCard>
            )}
          </div>
        </div>
      </div>
    </NavbarContainer>
  );
};

export default Navbar;
